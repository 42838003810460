import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Nav from "./components/Nav";
import Footer from "./components/Footer";
import ScrollToTop from "./utility/ScrollToTop";

import "normalize.css";
import "./styles/main.scss";

const Index = lazy(() => import("./pages/Home"));
const nycStudios = lazy(() => import("./pages/nycStudios"));
const nycStudios1 = lazy(() => import("./pages/nycStudios1"));
const nycStudios2 = lazy(() => import("./pages/nycStudios2"));
const nycStudios3 = lazy(() => import("./pages/nycStudios3"));
const nycCafeLobby = lazy(() => import("./pages/nycCafeLobby"));
const nycRoofDeck = lazy(() => import("./pages/nycRoofDeck"));
const bknStudios = lazy(() => import("./pages/bknStudios"));
const bknStudios1 = lazy(() => import("./pages/bknStudios1"));
const bknStudios2 = lazy(() => import("./pages/bknStudios2"));
const bknStudios3 = lazy(() => import("./pages/bknStudios3"));
const bknStudios4 = lazy(() => import("./pages/bknStudios4"));
const bknCafeLobby = lazy(() => import("./pages/bknStudiosCafeLobby"));
const bknGarden = lazy(() => import("./pages/bknStudiosGarden"));
const equipmentRentals = lazy(() => import("./pages/equipmentRentals"));
const equipmentProduction = lazy(() => import("./pages/equipmentProduction"));
const equipmentRepair = lazy(() => import("./pages/equipmentRepair"));
const digitalRentals = lazy(() => import("./pages/digitalRentals"));
const digitalCapture = lazy(() => import("./pages/digitalCapture"));
const digitalPost = lazy(() => import("./pages/digitalPost"));
const motionWork = lazy(() => import("./pages/motionWork"));
const motionRentals = lazy(() => import("./pages/motionRentals"));
const motionPost = lazy(() => import("./pages/motionPost"));
const motionProduction = lazy(() => import("./pages/motionProduction"));
const eventSpaces = lazy(() => import("./pages/eventSpaces"));
const eventRentals = lazy(() => import("./pages/eventRentals"));
const eventCoverage = lazy(() => import("./pages/eventCoverage"));
const eventPhotoBooth = lazy(() => import("./pages/eventPhotoBooth"));
const creativePartnerships = lazy(() => import("./pages/creativePartnerships"));
const creativeContentCreation = lazy(() => import("./pages/creativeContentCreation"));
const creativeBehindTheScenes = lazy(() => import("./pages/creativeBehindTheScenes"));
const creativeEcommerce = lazy(() => import("./pages/creativeEcommerce"));
const contact = lazy(() => import("./pages/contact"));
const careers = lazy(() => import("./pages/careers"));
const StoreTab = lazy(() => import("./pages/StoreTab"));
const StoreCategory = lazy(() => import("./pages/StoreCategory"));
const Product = lazy(() => import("./components/Product"));
const Products = lazy(() => import("./components/ProductGrid"));

function App() {
    return (
        <Router>
            <ScrollToTop>
                <Nav />

                <Suspense fallback={ <div className='root-suspense' /> }>
                    <Switch>
                        <Route path="/" exact component={ Index } />
                        <Route path="/studios/nyc-studios" exact component={ nycStudios } />
                        <Route path="/studios/nyc-studios/studio-1" exact component={ nycStudios1 } />
                        <Route path="/studios/nyc-studios/studio-2" exact component={ nycStudios2 } />
                        <Route path="/studios/nyc-studios/studio-3" exact component={ nycStudios3 } />
                        <Route path="/studios/nyc-studios/cafe-lobby" exact component={ nycCafeLobby } />
                        <Route path="/studios/nyc-studios/roof-deck" exact component={ nycRoofDeck } />
                        <Route path="/studios/bkn" exact component={ bknStudios } />
                        <Route path="/studios/bkn/studio-1" exact component={ bknStudios1 } />
                        <Route path="/studios/bkn/studio-2" exact component={ bknStudios2 } />
                        <Route path="/studios/bkn/studio-3" exact component={ bknStudios3 } />
                        <Route path="/studios/bkn/studio-4" exact component={ bknStudios4 } />
                        <Route path="/studios/bkn/cafe-lobby" exact component={ bknCafeLobby } />
                        <Route path="/studios/bkn/garden" exact component={ bknGarden } />
                        <Route path="/equipment/rentals" exact component={ equipmentRentals } />
                        <Route path="/equipment/production" exact component={ equipmentProduction } />
                        <Route path="/equipment/repair" exact component={ equipmentRepair } />
                        <Route path="/digital/rentals" exact component={ digitalRentals } />
                        <Route path="/digital/capture" exact component={ digitalCapture } />
                        <Route path="/digital/post" exact component={ digitalPost } />
                        <Route path="/motion/work" exact component={ motionWork } />
                        <Route path="/motion/rentals" exact component={ motionRentals } />
                        <Route path="/motion/post" exact component={ motionPost } />
                        <Route path="/motion/production" exact component={ motionProduction } />
                        <Route path="/spaces" exact component={ eventSpaces } />
                        <Route path="/events/rentals" exact component={ eventRentals } />
                        <Route path="/event-coverage" exact component={ eventCoverage } />
                        <Route path="/photo-booth/" exact component={ eventPhotoBooth } />
                        <Route path="/creative/partnerships/" exact component={ creativePartnerships } />
                        <Route path="/creative/content-creation/" exact component={ creativeContentCreation } />
                        <Route path="/creative/behind-the-scenes/" exact component={ creativeBehindTheScenes } />
                        <Route path="/creative/e-commerce/" exact component={ creativeEcommerce } />
                        <Route path="/contact/" exact component={ contact } />
                        <Route path="/careers/" exact component={ careers } />
                        <Route path="/products/" exact component={ Products } />
                        <Route path="/products/:uid" component={ Product } />
                        <Route path="/rentals/tab/:id" component={ StoreTab } />
                        <Route path="/rentals/category/:id" component={ StoreCategory } />
                    </Switch>
                </Suspense>

                <Footer />
            </ScrollToTop>
        </Router>
    );
}

export default App;
