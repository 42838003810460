import React, { useState } from "react";
import Logo from "../img/logo.svg";
import cx from "classnames";
import Headroom from "react-headroom";
import NavGroup from "../components/nav/group";
import { NavLink as Hyper } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram, faTwitter, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import CartIcon from "../components/CartIcon";

const Nav = () => {
    const [ navOpen, setNavOpen ] = useState(false);

    const navClasses = cx({
        "c-nav-overlay": true,
        "js-nav-overlay": true,
        "is-visible": navOpen
    });

    const toggleNav = () => {
        setNavOpen(wasNavOpen => !wasNavOpen);
    };

    const NavToggle = () => {
        const navToggleClasses = cx({
            "c-nav-toggle": true,
            "js-nav-toggle": true,
            "collapse": navOpen
        });

        return (
            <div className={ navToggleClasses } onClick={ toggleNav }>
                <div className="c-nav-toggle-line" />
            </div>
        );
    };

    return (
        <>
            <Headroom disableInlineStyles>
                <div className="header">
                    <header className="c-site-header">
                        <Hyper to="/" className="c-site-logo js-site-logo">
                            <img src={ Logo } alt="Root NYC Logo" />
                        </Hyper>

                        <div className="nav-right">
                            <NavToggle />
                            <Hyper className="nav-cart" to="/products">{ CartIcon }</Hyper>
                        </div>
                    </header>
                </div>
            </Headroom>

            <section className={ navClasses }>
                <nav className="c-site-nav">
                    <NavGroup clickHandle={ toggleNav } mainNavOpen={ navOpen } key="12" title="NYC Studios" links={
                        [
                            [ "/studios/nyc-studios/", "Explore All" ],
                            [ "/studios/nyc-studios/studio-1", "Studio 1" ],
                            [ "/studios/nyc-studios/studio-2", "Studio 2" ],
                            [ "/studios/nyc-studios/studio-3", "Studio 3" ],
                            [ "/studios/nyc-studios/cafe-lobby", "Cafe Lobby" ],
                            [ "/studios/nyc-studios/roof-deck", "Roof Deck" ]
                        ]
                    } />

                    <NavGroup clickHandle={ toggleNav } mainNavOpen={ navOpen } key="13" title="BKN Studios" links={
                        [
                            [ "/studios/bkn/", "Explore All" ],
                            [ "/studios/bkn/studio-1", "Studio 1" ],
                            [ "/studios/bkn/studio-2", "Studio 2" ],
                            [ "/studios/bkn/studio-3", "Studio 3" ],
                            [ "/studios/bkn/studio-4", "Studio 4" ],
                            [ "/studios/bkn/cafe-lobby", "Cafe Lobby" ],
                            [ "/studios/bkn/garden", "Garden" ]
                        ]
                    } />

                    <NavGroup clickHandle={ toggleNav } mainNavOpen={ navOpen } key="14" title="Equipment" links={
                        [
                            [ "/equipment/rentals/", "Rentals" ],
                            [ "/equipment/production", "Production" ],
                            [ "/equipment/repair", "Repair" ]
                        ]
                    } />

                    <NavGroup clickHandle={ toggleNav } mainNavOpen={ navOpen } key="15" title="Digital" links={
                        [
                            [ "/digital/rentals/", "Rentals" ],
                            [ "/digital/capture", "Capture" ],
                            [ "/digital/post", "Post" ]
                        ]
                    } />

                    <NavGroup clickHandle={ toggleNav } mainNavOpen={ navOpen } key="16" title="Motion" links={
                        [
                            [ "/motion/work/", "Work" ],
                            [ "/motion/rentals", "Rentals" ],
                            [ "/motion/post", "Post" ],
                            [ "/motion/production", "Production" ]
                        ]
                    } />

                    <NavGroup clickHandle={ toggleNav } mainNavOpen={ navOpen } key="17" title="Events" links={
                        [
                            [ "/spaces/", "Spaces" ],
                            [ "/equipment/rentals", "Rentals" ],
                            [ "/event-coverage", "Event Coverage" ],
                            [ "/photo-booth", "Photo Booth" ]
                        ]
                    } />

                    <NavGroup clickHandle={ toggleNav } mainNavOpen={ navOpen } key="18" title="Creative" links={
                        [
                            [ "/creative/partnerships/", "Partnerships" ],
                            [ "/creative/content-creation", "Content Creation" ],
                            [ "/creative/behind-the-scenes", "Behind the Scenes" ],
                            [ "/creative/e-commerce", "E commerce" ]
                        ]
                    } />
                </nav>

                <nav className="c-secondary-nav">
                    <li><Hyper onClick={ toggleNav } to="/contact/">Contact</Hyper></li>
                    <li><Hyper onClick={ toggleNav } to="/careers/">Careers</Hyper></li>
                </nav>

                <div className="social-links">
                    <a href="https://www.facebook.com/ROOT.NYC.BKN" rel="noopener noreferrer" target="_blank">
                        <FontAwesomeIcon icon={ faFacebookF } />
                    </a>

                    <a href="https://www.linkedin.com/company/root-studios-nyc-/" rel="noopener noreferrer" target="_blank">
                        <FontAwesomeIcon icon={ faLinkedinIn } />
                    </a>

                    <a href="https://twitter.com/rootstudios" rel="noopener noreferrer" target="_blank">
                        <FontAwesomeIcon icon={ faTwitter } />
                    </a>

                    <a href="https://www.instagram.com/rootstudios/" rel="noopener noreferrer" target="_blank">
                        <FontAwesomeIcon icon={ faInstagram } />
                    </a>
                </div>
            </section>
        </>
    );
};

export default Nav;
