import React from "react";

const CartIcon =
    <svg width="22px" height="32px" viewBox="0 0 22 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <defs>
            <polygon id="path-1" points="0 0.0002 22 0.0002 22 32.0002 0 32.0002"></polygon>
        </defs>

        <g id="Artboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="cart">
                <g id="Clip-2"></g>
                <path d="M17.3104562,7.53277842 L17.3104562,9.0337877 C18.1976405,9.48379443 17.8848069,10.8417095 16.8801866,10.8417095 L15.1048311,10.8417095 C13.8485623,10.8417095 13.8485623,8.93214145 15.1048311,8.93214145 L15.4008882,8.93214145 L15.4008882,7.53277842 L6.59911183,7.53277842 L6.59911183,8.93214145 L6.89615574,8.93214145 C8.15143767,8.93214145 8.15143767,10.8417095 6.89615574,10.8417095 L5.11981339,10.8417095 C4.11519311,10.8417095 3.80235949,9.48379443 4.6895438,9.0337877 L4.6895438,7.53277842 L1.90956803,7.53277842 L1.90956803,29.8811389 L20.0914188,29.8811389 L20.0914188,7.53277842 L17.3104562,7.53277842 Z M6.65338896,5.62419725 L15.3475979,5.62419725 C15.0110797,3.49653386 13.1597362,1.90966803 11.0004934,1.90966803 C8.84322433,1.90966803 6.98990715,3.49949443 6.65338896,5.62419725 L6.65338896,5.62419725 Z M0.954290584,5.62419725 L4.72704436,5.62419725 C5.07639169,2.43961016 7.79123492,0.0001 10.9995066,0.0001 C14.2087651,0.0001 16.9236083,2.44059702 17.2729556,5.62419725 L21.0457094,5.62419725 C21.572691,5.62419725 22,6.05150627 22,6.57848783 L22,30.8354295 C22,31.3614242 21.572691,31.7897201 21.0457094,31.7897201 L0.954290584,31.7897201 C0.428295878,31.7897201 0,31.3614242 0,30.8354295 L0,6.57848783 C0,6.05150627 0.428295878,5.62419725 0.954290584,5.62419725 L0.954290584,5.62419725 Z" id="Fill-1" fill="#ffffff" mask="url(#mask-2)"></path>
            </g>
        </g>
    </svg>;

export default CartIcon;
