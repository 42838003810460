import React, { useState, useEffect } from "react";
import cx from "classnames";
import { NavLink as Hyper } from "react-router-dom";

const NavGroup = props => {
    const [ groupOpen, setGroupOpen ] = useState(false),
        { title, links, clickHandle, mainNavOpen } = props;

    useEffect(() => {
        if (!mainNavOpen) {
            setGroupOpen(false);
        }
    }, [ mainNavOpen ]);

    const subMenuClasses = cx({
        "c-site-nav-submenu": true,
        "js-submenu": true,
        "is-open": groupOpen
    });

    return (
        <li>
            <button
                type="button"
                style={ { outline: "none", padding: 0, backgroundColor: "transparent", border: 0 } }
                onClick={ () => setGroupOpen(was => !was) } className="c-site-nav-label js-submenu-toggle">

                { title }
            </button>

            <nav className={ subMenuClasses }>
                {
                    links.map((link, index) =>
                        <li key={ index }>
                            <Hyper to={ link[0] } onClick={ () => { setGroupOpen(false); clickHandle(); } }>
                                { link[1] }
                            </Hyper>
                        </li>
                    )
                }
            </nav>
        </li>
    );
};

export default NavGroup;
